@keyframes spin {
  0% {
    transform-origin: center;
    transform: rotate(0deg); }
  100% {
    transform-origin: center;
    transform: rotate(360deg); } }

svg .circle-path {
  animation: spin 10s linear infinite; }
