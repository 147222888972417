aside.cv-footer {
    position: absolute;
    background-color: #0e161e;
    bottom: 0;
    right: 0;
    width: calc((100% / 12) * 7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    color: #4AF626;
    font-size: 20px;
    border-top: double .4rem #4AF626;
    border-left: double .4rem #4AF626;
    padding: .5rem .75rem;
    padding-left: 1.25rem;
    border-top-left-radius: 50px;
    // span
    //     background-color: #4AF626
    // span.vertical
    //     height: 2rem
    //     width: 2px
    // span.horizontal
    //     height: 2px
    //     width: 1rem
    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        list-style: none;
        li {
            display: flex;
            align-items: center;
            gap: .5rem;
            a {
                font-size: 14px;
                color: white; }
            span {
                width: 1rem;
                height: 3px;
                background-color: #4AF626;
                margin-left: 0; } } } }

@media screen and (max-width: 210mm) {
    aside.cv-footer {
        position: initial;
        width: 100%;
        border-radius: 0px;
        border: none;
        background: transparent;
        ul li a {
            font-size: 12px; } } }
