.home-page {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10vh;
  section {
    width: 100%;
    /* A4 paper width (border included) */
    max-width: calc(210mm - 2px);
    /* A4 paper height (border included) */
    height: calc(297mm - 2px);
    margin: 0 auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    background-color: #212529;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: solid 1px #4AF626;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 300px 4px;
    .cv-main {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      position: relative;
      article {
        display: flex;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: .5rem;
        &:first-of-type {
          order: 1;
          width: calc((100% / 12) * 5);
          h3 {
            margin-top: 0; } }
        &:last-of-type {
          order: 2;
          width: calc((100% / 12) * 7);
          .cv-picture {
            display: none; }
          ul.icons {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
            gap: 0 .5rem;
            list-style: none;
            padding-top: .25rem;
            li {
              flex-shrink: 0;
              padding: 0;
              span {
                font-size: 2rem;
                text-decoration: none;
                margin-left: 0; } } }
          aside {
            margin-top: .25rem;
            width: 100%;
            display: flex;
            justify-content: space-between; } }
        .cv-picture {
          width: 100%;
          padding-left: .8rem;
          overflow: visible;
          display: flex;
          justify-content: center;
          picture {
            box-shadow: rgba(#4AF626, 0.3) -5px 5px, rgba(#4AF626, 0.2) -10px 10px, rgba(#4AF626, 0.1) -15px 15px, rgba(#4AF626, 0.05) -20px 20px;
            width: 300px;
            height: 300px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center; } } }
        .content {
          width: 100%;
          height: 100%;
          position: relative;
          padding: .5rem;
          .privacy {
            // position: absolute
            // bottom: .5rem
            // left: .5rem
            width: 100%;
            padding-right: 3rem;
            margin-top: 1.5rem;
            p {
              line-height: 12px;
              font-size: 12px;
              display: flex;
              align-items: center;
              gap: 0.5rem;
              & > * {
                font-size: 20px; }
              &:first-of-type {
                font-size: 10px; } } }
          // rottigni.tech h3
          & > h3:first-child {
            margin-top: 0; } } }
      .pagination {
        position: absolute;
        bottom: 4px;
        left: -45px;
        color: #4AF626;
        font-size: 18px;
        span {
          font-size: bold;
          color: white;
          font-size: 18px; } } } } }

.mt-2 {
  margin-top: .5rem !important; }
.img-item {
  line-height: 20px;
  vertical-align: middle;
  img {
    margin: 0 .5rem; } }
/* responsive paper sheet */
@media screen and (max-width: 210mm) {
  .home-page {
    padding: 0;
    section {
      max-width: 100%;
      height: 100%;
      padding: 10px;
      box-shadow: none;
      .cv-main {
        flex-direction: column;
        article {
          &:last-of-type {
            width: 100%;
            order: 1;
            .cv-picture {
              display: flex; }
            aside {
              flex-direction: column; } }
          &:first-of-type {
            width: 100%;
            order: 2;
            .cv-picture {
              display: none; } }
          .content .privacy {
            display: none; } } } } } }
