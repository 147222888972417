header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  z-index: 100;
  & > a {
    padding: .7rem 1.5rem;
    border-radius: 30px;
    background-color: #4AF626;
    color: #0e161e;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem; }
  ul {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: .5rem 1rem;
    border-radius: 30px;
    list-style: none;
    border: double 4px #4AF626;
    background-color: #0e161e;
    li {
      border-radius: 20px;
      a {
        text-decoration: none;
        display: flex;
        img {
          width: 35px;
          height: auto; } }
      &.separator {
        width: 2px;
        height: 40px;
        background-color: #4AF626; } } } }

@media screen and (max-width: 210mm) {
  header {
    position: fixed;
    align-items: center;
    z-index: 20;
    background-color: #0e161e;
    border-bottom: solid 1px #4AF626;
    & > a {
      font-size: 1rem; }
    ul {
      li a img {
        width: 25px;
        height: auto; }
      li.separator {
        height: 23px; } } } }
