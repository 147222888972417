* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'M PLUS 1 Code', sans-serif;
  color: white; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

ul, ol {
  list-style-position: inside;
  margin-top: 0.25rem;
  li {
    padding: .15rem 0; } }
li, p, a {
  font-size: 13px; }
li span {
  text-decoration: underline;
  margin-left: .5rem; }
p {
  margin-top: .5rem;
  a {
    margin-left: .25rem; } }

h1 {
  font-size: 2.5rem; }
h2 {
  font-size: 1.3rem;
  margin-top: .5rem;
  color: #4AF626; }
h3 {
  font-size: 1.5rem;
  margin: .5rem 0;
  display: flex;
  align-items: center;
  gap: .5rem; }
h4 {
  font-size: 1.1rem;
  margin: .5rem 0;
  color: #4AF626;
  span {
    text-decoration: underline;
    font-size: 0.7rem;
    margin-left: .5rem; } }
h5 {
  font-size: 1rem;
  margin-top: .5rem;
  span {
    text-decoration: underline;
    font-size: 0.8rem;
    margin-left: .5rem; } }
h6 {
  font-size: 1.1rem;
  margin: .25rem 0; }
