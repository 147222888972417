.default-layout {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  padding: 10vh 0;
  &::-webkit-scrollbar {
    width: .4rem;
    border-radius: 30px;
    background-color: #0e161e; }

  &::-webkit--track {
    background: transparent; }

  &::-webkit-scrollbar-thumb {
    background-color: #4AF626; } }

.mobile-warning-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  * {
    text-align: center; }
  button {
    margin: 0 auto;
    background: transparent;
    border: 1px solid #4AF626;
    color: #4AF626;
    padding: .5rem 1.5rem;
    border-radius: 10px;
    text-decoration: underline;
    cursor: pointer; } }


.bg-gradient {
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, black 0%, #262b2f 25%, #0e161e 51%, black 100%);
  -webkit-animation: move-bg 10s ease infinite;
  animation: move-bg 10s ease infinite; }

@-webkit-keyframes move-bg {
  0% {
    background-position: 0% 50%; }

  50% {
    background-position: 100% 50%; }

  100% {
    background-position: 0% 50%; } }

@keyframes move-bg {
  0% {
    background-position: 0% 50%; }

  50% {
    background-position: 100% 50%; }

  100% {
    background-position: 0% 50%; } }

@media screen and (max-width: 210mm) {
  .default-layout {
    padding: 20vh 0; }
  .bg-gradient {
    background: none; } }
