footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 0;
  .sign {
    background-color: #0e161e;
    padding: 1rem 3rem;
    border-top: solid 1px #4AF626;
    border-right: solid 1px #4AF626;
    border-top-right-radius: 35px;
    font-size: 1rem;
    span {
      color: #4AF626;
      margin: 0 .5rem;
      font-size: 1rem;
      line-height: 1rem; }
    a {
      margin-left: .5rem;
      font-size: 1rem;
      font-weight: bold; } }
  .logo {
    padding: 0;
    margin: 3rem;
    border-radius: 50%;
    border-top-left-radius: 50%; } }

@media screen and (max-width: 210mm) {
  footer {
    z-index: 20;
    .logo {
      display: none; }
    .sign {
      border: solid 1px #4AF626;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      font-size: 14px;
      padding: .6rem 3rem;
      a {
        font-size: 14px; } } } }
